<template>
  <nav class="navbar navbar-expand-lg px-0 px-md-5">
    <div class="container-fluid d-flex align-items-center">
      <a class="nav-brand d-block d-lg-none" href="#">
        <img class="img-fluid" width="70" :src="require('@/assets/PNG LOGO 2 w.png')" alt="Small Logo" />
      </a>
      <a class="nav-brand d-none d-lg-block" href="#">
        <img class="img-fluid" width="122" :src="require('@/assets/PNG LOGO 2 w.png')" alt="Large Logo" />
      </a>
      <button
        class="navbar-toggler text-center"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-list text-light" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
        </svg>
      </button>
      <div class="collapse navbar-collapse d-md-flex justify-content-center text-uppercase" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link px-lg-4 px-2 fw-bolder" href="#partners">Partners</a>
          </li>
          <li class="nav-item">
            <a class="nav-link px-lg-4 px-2 fw-bolder" href="#whoWeAre">About Us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link px-lg-4 px-2 fw-bolder" href="#factory">Factory</a>
          </li>
          <li class="nav-item">
            <a class="nav-link px-lg-4 px-2 fw-bolder" href="#showroom">Showroom</a>
          </li>
          <li class="nav-item">
            <a class="nav-link px-lg-4 px-2 fw-bolder" href="#contacts">Contact Us</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>

</script>

<style scoped>
nav {
  background-color: rgba(255, 255, 255, 0.150);
}

.nav-link.active,
.nav-link:active,
.nav-link:focus {
  color: #cc2d2d !important;
}
li a {
  color: #fafafa;
  font-size: 1rem;
}
li a:hover {
  color: #cc2d2d;
}
.navbar {
  position: relative;
}
@media (max-width: 575.98px){
  .nav-link.active,
.nav-link:active,
.nav-link:focus {
  color: #cc2d2d !important;
  background-color: #fafafa !important;
  }
}
</style>
