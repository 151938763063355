<script setup>

</script>

<template>
  <div class="container-fluid position-relative sectionContent">
<div class=" text-uppercase sectionFactory">
  <div class="row row-cols-2 h-100 overflow-hidden"> 
  <div class="col-12 col-md-4 d-none d-md-block">
    <img class="img-fluid sideImage" src="../assets/sections/factory/div.png" alt="factory">
  </div>
    <div class="col-12 col-md-8">
    <h2 class="w-100 my-3 my-md-5">
      our factory
    </h2>
    <p class="my-3 my-md-5">
      Our factory established in 2009 positioned at Abo Rawash to fully assemble & prepare motorcycle.
    </p>
    <h3 class="subtitle">spare parts we work on:</h3>
    <div class="row row-cols-2 w-100 m-2 mt-4 mt-md-0 m-md-5">
    <ul class="col-6">
      <li>
        headlight
      </li>
      <li>
        turnsignal
      </li>
      <li>
        brake caliper
      </li>
      <li>
        hand clutch
      </li>
      <li>
        ignition switch speedometer housing
      </li>
      <li>
        full motorcycle chassis
      </li>
      <li>
        motorcycle horn
      </li>
    </ul>
    <div class="col-6 d-flex justify-content-start align-items-center">
      <img class="img-fluid" src="../assets/sections/factory/span.png" alt="upper">
    </div>
    </div>
    <p class="text-center text-md-start mt-5">
      our spare parts warehouses space: 1400M, 1000M
    </p>
    </div>
  </div>
</div>
</div>
</template>

<style scoped>
.sideImage{
  height: 100%;
}
.subtitle{
  font-size: 2vw;
  color: #A1060B;
}
.sectionContent{
  margin-top: 5vh ;
  background-color: #f5f5f5;
  height: 100vh;
}
.sectionFactory{
  height: 100%;

}
li, ul, h2, h3, p{
  color: #000;
}

h2{
  font-size: 5vw;
}
@media (max-width: 575.98px) {
  .sectionContent{
    height: 75vh;
  }
  .subtitle{
  font-size: 5vw;
  color: #A1060B;
}
  h2{
    margin-top: 2vh;
    font-size: 8vw;
  }
  h3{
    font-size: 6vw;
  }
  .upperImages{
    left: 15vw;
    top:5vw;
    height: 8vh;
    width: 65vw;
  }
  .lowerImage{
    width: 50vw;
  bottom: -7vw;

  }
}
</style>