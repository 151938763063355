<script setup>

</script>

<template>
<div class="zogmarName zogmarMainText mainTextSize text-white z-2">
  <h1 class="text-center">
    ZOGMAR 
    <span class="d-block">EST.1950</span>
    <a class="btn btn-danger callToAction w-75" href="#contacts">Contact Us</a>
  </h1>
</div>

    <div class="mainSection">
      <video class="w-100 h-100 opacity-75  d-none d-lg-block" autoplay muted loop playsinline>
    <source src="../assets/bg/video.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>
      <video class="w-100 h-100 opacity-75  d-block d-lg-none" autoplay muted loop playsinline>
    <source src="../assets/bg/video-mobile.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>
    </div>

</template>

<style scoped>
@font-face {
  font-family: 'CustomFont';
  src: url('@/assets/Azeri-Sans-Regular/AzeriSans-SemiBold.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}
.mainSection{
  background-color: #000;
}
.callToAction{
  background-color: #A1060B;
  color: #fafafa;
  font-size: 1.5vw;
}
.zogmarMainText{
    font-family: 'CustomFont', sans-serif;
  letter-spacing: 3px ;
  position: absolute;
}
.zogmarName{
  top:40%;
  left:5%;
}
.mainTextSize  h1{
  line-height: 1.2;
  color:#fff;
  font-size: 4.5vw;
}
.zogmarName span{
  font-size: 3.5vw;
}
@media (max-width: 575.98px){
  .zogmarName span{
    font-size: 5.5vw;
}
.zogmarName{
  width: 100%;
  top:50%;
  left:0;
}
.mainTextSize h1{
  font-size: 10vw;
}
.callToAction
{
  margin-top: 2rem;
  width: 50% !important;
  font-size: 5vw;
}
}

</style>