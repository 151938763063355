<script setup></script>

<template>
  <!-- Footer -->
  <footer
    id="footerEN"
    class="position-relative text-center text-lg-start text-white"
    style="background-color: #010101"
  >
  
    <!-- Section: Links  -->
    <section class="pt-4">
      
      <div class="container text-center text-md-start mt-5">
        <p class="text-center">
          <a href="https://www.instagram.com/zogmar_egymotors" class="me-5 me-md-3" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#ffffff" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
        </svg>
        <span class="d-none d-md-inline">
          @zogmar_egymotors
        </span>
        </a>

        <a href="https://www.facebook.com/Zogmar.EGY.Motors" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#ffffff" d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"/></svg>
          <span class="d-none d-md-inline">
            Zogmar EGY Motors
          </span>
        </a>
        </p>
        <!-- Grid row -->
        <div class="row mt-3">
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 mt-4 fs-5">
            <!-- Links -->
            <h4 class="fw-semibold mb-4">Company</h4>
            <p>
              <a href="#partners" class="text-decoration-none text-white">Partners</a>
            </p>
            <p>
              <a href="#whoWeAre" class="text-decoration-none text-white">About Us</a>
            </p>
            <p>
              <a href="#factory" class="text-decoration-none text-white">Factory</a>
            </p>
            <p>
              <a href="#showroom" class="text-decoration-none text-white">Showroom</a>
            </p>
          </div>

          <!-- Grid column -->
          <div class="col-md-4 col-lg-4 col-xl-4 mx-auto mb-md-0 mb-4 mt-4">
            <!-- Links -->
            <h4 class="fw-semibold mb-4">Reach us.</h4>
            <p>
              Factory: ABO RAWASH
            </p>
            <p>
              Showroom: ELMOKKATAM AL-QODS ST. FROM 9TH
                            STREET.
            </p>
            <p>
              E-mail: ahmed@zogmareg.com
            </p>
            <p>
              Phone: 01093555584 
            </p>
          </div>
          <!-- Grid column -->
          
          <!-- Grid column -->
          <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4 mt-4">
            <!-- Content -->
            <img
              class="image-fluid mx-auto pb-4 d-block d-md-none"
              src="../assets/PNG LOGO 2 w.png"
              alt=""
              height="100"
            />
            <h6 class="fw-semibold fs-5 mb-3">ZOGMAR EGY MOTORS</h6>
            <p>
              "Zogmar, Egypt's exclusive distributor for top factories and brands in spare parts, offering advanced assembly, nationwide distribution, and post-sale services including warranties and maintenance."
            </p>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
    </section>
    <!-- Section: Links  -->

    <!-- Copyright -->
    <div class="container text-center p-4 border-top border-light-subtle" style="--bs-border-opacity: .5;" >
      <p>
        Any dealings made outside of these contacts and addresses are not affiliated with ZOGMAR, the owner of this website.
      </p>
      © Copyright ZOGMAR 2024 <br />
      <a class="text-white" href="#">Created by Genie Team</a><br>
      
    </div>
    <!-- Copyright -->
    <img
      class="img-fluid d-none d-md-block position-absolute opacity-25"
      src="../assets/sections/footer.png"
      alt=""
    />
  </footer>
  <!-- Footer -->
</template>

<style scoped>
section {
  position: relative;
  z-index: 99;
}
img {
  bottom: 0;
  z-index: 0;
}
svg{
  width:1.5rem;
}
a{
  text-decoration: none;
  color: white;
}
</style>
