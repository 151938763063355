
<script setup>

</script>
<template>
<div class="container position-relative ">
  <div class="clientsSection">
    <h3 class="my-3 mb-md-5 clientsHeading">CLIENTS TRUSTED US.</h3>
    <div class="d-flex justify-content-center align-items-center m-2 m-md-4">
      <img class="img-fluid " src="../assets/sections/clients/Emblem_of_the_Egyptian_Armed_Forces.png" alt="">
      <img class="img-fluid middlePic" src="../assets/sections/clients/al-ahram-logo-6E1489F7B0-seeklogo.com.png" alt="">
      <img class="img-fluid " src="../assets/sections/clients/images.png" alt="">
    </div>
  </div>
</div>
</template>

<style scoped>
.clientsSection{
  margin: 5vw 0;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
.clientsHeading{
  position: absolute;
  top: -2vw;
  left:50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 0 1vw;
  color:#000;
  font-weight: 600;
  font-size: 1.5vw;
}
.middlePic{
  margin: 0 5vw;
  height: 6vw;
  width: auto;
}
img{
  width: 7vw;
}
@media (max-width: 768px) {
  img{
    margin-top: 2vw;
    width: 15vw;
  }
  .middlePic{
  margin: 0 7vw;
  height: 10vw;
  width: auto;
}
.clientsHeading{
  color:#000;
  font-weight: 600;
  font-size: 3vw;
  position: absolute;
  top: -5.5vw;
  left: 50%;
  transform: translateX(-50%);
}
}
</style>