<script setup>
import { onMounted, ref } from "vue";

const startX = ref(0);
const scrollLeft = ref(0);
const isDown = ref(false);

onMounted(() => {
  const logos = document.querySelector(".logos");

  // Clone the logos-slide div to create an infinite loop effect
  const copy = document.querySelector(".logos-slide").cloneNode(true);
  document.querySelector(".logos").appendChild(copy);

  // Handle mouse wheel for horizontal scrolling
  logos.addEventListener("wheel", (e) => {
    e.preventDefault();
    logos.scrollLeft += e.deltaY; // Move the logos container horizontally
  });

  // Handle dragging with mouse
  logos.addEventListener("mousedown", (e) => {
    isDown.value = true;
    logos.classList.add("active");
    startX.value = e.pageX - logos.offsetLeft;
    scrollLeft.value = logos.scrollLeft;
  });

  logos.addEventListener("mouseleave", () => {
    isDown.value = false;
    logos.classList.remove("active");
  });

  logos.addEventListener("mouseup", () => {
    isDown.value = false;
    logos.classList.remove("active");
  });

  logos.addEventListener("mousemove", (e) => {
    if (!isDown.value) return;
    e.preventDefault();
    const x = e.pageX - logos.offsetLeft;
    const walk = (x - startX.value) * 3; // Scroll faster
    logos.scrollLeft = scrollLeft.value - walk;
  });

  // Handle touch events for mobile (swipe left/right)
  let touchStartX = 0;
  let touchMoveX = 0;

  logos.addEventListener("touchstart", (e) => {
    touchStartX = e.touches[0].pageX;
    scrollLeft.value = logos.scrollLeft;
  });

  logos.addEventListener("touchmove", (e) => {
    touchMoveX = e.touches[0].pageX;
    const walk = touchStartX - touchMoveX;
    logos.scrollLeft = scrollLeft.value + walk;
  });
});
</script>

<template>
  <div>
  <div class="logos">
    <div class="logos-slide z-3">
      <a href="https://mtxparts.com/" target="_blank">
        <img src="../assets/logos/image 9-1.png" alt="mtx" />
      </a>
      <a href="https://www.scoyco.com/en/" target="_blank">
        <img src="../assets/logos/images 2.png" alt="scoyco" />
      </a>
      <a href="https://motorex.com/" target="_blank">
        <img src="../assets/logos/motorex.png" alt="motorex" />
      </a>
      <a href="https://ls2helmets.com/" target="_blank">
        <img
          src="../assets/logos/b045be3e5c22b0c454a19c7e96309442.w2048.h1272._CR0,700,2048,410_SX1500_ 1.png"
        alt="ls2" />
      </a>
      <a href="https://www.metzeler.com/en-ww/home" target="_blank">
        <img src="../assets/logos/png-transparent-metzeler-hd-logo 1.png" alt="metzeler" />
      </a>
      <a href="https://www.motobatt.us/" target="_blank">
        <img src="../assets/logos/images 1.png" alt="motobatt" />
      </a>
      <a href="https://www.iris-chains.com/en/" target="_blank">
        <img src="../assets/logos/image 9.png" alt="iris" />
      </a>
      <a href="https://www.mitas-moto.com/it-it" target="_blank">
        <img src="../assets/logos/0003834_mitas 1.png" alt="mitas" />
      </a>
      <a href="https://www.kagebatteries.com/" target="_blank">
        <img src="../assets/logos/kage-11-169x69 2.png" alt="kage" />
      </a>
      <a href="https://ixil.com/en" target="_blank">
        <img src="../assets/logos/image 9-2.png" alt="ixil" />
      </a>

    </div>
  </div>
</div>
</template>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.heading{
  font-weight: 700;
  font-size: 3.5vw;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 3vw 0;
  margin: 4vw 0;
  white-space: nowrap;
  position: relative;
  background-color: #f5f5f5;
}

.logos.active {
  cursor: grabbing;
}

.logos:before,
.logos:after {
  content: "";
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  z-index: 2;
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-block;
  animation: 35s slide infinite linear;
}

.logos-slide img {
  height: 4vw;
  margin: 0 1.5vw;
}

@media (max-width: 575.98px) {
  .logos-slide img {
  height: 9vw;
  margin: 0 1.5vw;
}
  .logos {
    padding: 20px 0;
    cursor: default; /* Disable grab cursor on mobile */
  }
  .logos:before,

  .logos-slide {
    animation-duration: 50s; /* Slow down the animation for mobile */
  }
}
</style>
