<script setup></script>

<template>
  <div class="container-fluid contentContainer">
  <div class="row rowContent">
    <div class="col-12 col-md-6 position-relative text-uppercase">

      <!-- content to show -->
      <transition name="fade">
      <div v-if="showMoreContent1" class="ps-1 ps-md-3 pt-3 position-relative content">
      <h1 class="text-start mt-4 mt-md-5">Why US?</h1>
        <div class="w-75">
        <p class="paragraphAbout">we have distributors in all of<span class="text-danger"> egypt.</span></p>
      </div>
      <div class="w-50">
        <p class="paragraphAbout">Exclusive agent for the
     <span class="text-danger">biggest factories & brands</span> 
that works in spare parts.</p>
      </div>
      <div class="w-75">
        <p class="paragraphAbout"> <span class="text-danger"> Post sale services:</span> 
warranties against
defects, maintenance, 
spare parts availability.</p>
      </div>
      <div class="w-75">
        <p class="paragraphAbout">by the name of
Zogmar, <span class="text-danger"> 13 certified </span>
distributor that works with
us in Cairo & Giza.
</p>
      </div>
      <div class="w-75">
        <p class="paragraphAbout">we have an assembly & 
preparation <span class="text-danger"> factory </span> that 
working by the latest technology.
</p>
      </div>
        <!-- read less span -->
      <span class="text-decoration-underline d-flex align-items-center" @click="toggleReadMore">
          read less
          <svg class="ms-2" width="16" height="16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
        </span>
      </div>
      </transition>
      <!-- content to show -->
      <div v-if="!showMoreContent1" class="h-100 w-100 position-relative content d-flex flex-column justify-content-center align-items-center ">
        <h1 class="text-start mt-4 mt-md-5">Why US?</h1>
        <span class="text-decoration-underline d-flex align-items-center" @click="toggleReadMore">
          read more
          <svg class="ms-2" width="16" height="16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
        </span>
      </div>
      <img
        class="img-fluid h-100 position-absolute top-0 end-0 imageBG opacity-25"
        src="../assets/sections/whoWeAre/rider new logo 2.png"
        alt="" 
      />
    </div>
    <div class="col-12 col-md-6 position-relative text-uppercase">
    <!-- content to show -->
    <transition name="fade">
      <div v-if="showMoreContent2" class="ps-1 ps-md-3 pt-3 position-relative content">
      <h1 class="text-start my-4 my-md-5 ">Who are we?</h1>
        <div class="w-75">
        <h2 class="headingAbout">quality masters</h2>
        <p class="paragraphAbout">we import original motorcycle spare parts brands.</p>
      </div>
      <div class="w-75">
        <h2 class="headingAbout">pioneers</h2>
        <p class="paragraphAbout">zogmar brand is the first to import motorcycles & its spare parts to egypt.</p>
      </div>
      <div class="w-75">
        <h2 class="headingAbout">highly trusted</h2>
        <p class="paragraphAbout">we distribute to all egypt's governorates.</p>
      </div>
        
          <!-- read less span -->
          <span class="text-decoration-underline d-flex align-items-center" @click="toggleReadMore2">
          read less
          <svg class="ms-2" width="16" height="16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
        </span>
      </div>
      </transition>
      <!-- content to show -->
      <div  v-if="!showMoreContent2" class="h-100 w-100 position-relative content d-flex flex-column justify-content-center align-items-center ">
        <h1 class="text-start mt-4 mt-md-5">Who are we?</h1>
        <span class="text-decoration-underline d-flex align-items-center" @click="toggleReadMore2">
          read more
          <svg class="ms-2" width="16" height="16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
        </span>
      </div>
      <img
        class="img-fluid h-100 position-absolute top-0 start-0 imageBG opacity-25"
        src="../assets/sections/whoWeAre/rider new logo 1.png"
        alt="" 
      />
    </div>
  </div>
  </div>
</template>
<script>
  export default {
    name: 'WhoWeAreSection',
    data() {
      return {
        showMoreContent1: false,
        showMoreContent2: false
      };
    },
    methods: {
      toggleReadMore(){
        this.showMoreContent1 = !this.showMoreContent1;
        this.showMoreContent2 = false;
      },
      toggleReadMore2(){
        this.showMoreContent2 = !this.showMoreContent2;
        this.showMoreContent1 = false;
      }
    }
  }

</script>
<style scoped>
span{
  cursor: pointer;
}
/* Fade transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

/* Additional styles */
.contentContainer {
  height: 100vh;
  background-color: #000;
  color: #fff;
}

.contentContainer{
  height: 100vh;
  background-color: #000;
  color: #fff;
}
.rowContent
{
  height: 100%;
}
.content
{
  z-index: 2;
}
.imageBG
{
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

@media(max-width: 575.98px){
  .contentContainer{
    height: 100vh;
  }
  .rowContent{
    height: 100%;
  }
  .content{
    z-index: 2;
  }
  .imageBG
  {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
}

</style>
